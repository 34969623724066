body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.protected-class {
  font-size: 9px;
  pointer-events: none;
  opacity:0.4;
}

@media (min-width: 1200px) {
  .RaLayout-appFrame, .css-aof9pm-MuiPaper-root-MuiAppBar-root-RaAppBar-root, .RaAppBar-toolbar{
    width: 1200px;
    margin: 0 auto;
  }

}

.password-sms input{
  width: 126px;
}

/*.test {*/
  /*width: 1200px;*/
  /*margin: 0 auto;*/
/*}*/

